<template>
    <div class="mianbox">
        <div class="searchbox">
            <el-form ref="form" :model="form" label-width="80px" class="searchbox_left">
                <el-form-item class="leftbox"> 
                    <div class="left_one">
                        <el-input
                            placeholder="请输入角色名称查询"
                            v-model="form.name"
                            size="small">
                            <i slot="prefix" class="el-input__icon el-icon-search"></i>
                        </el-input>
                    </div> 
                    <div class="left_three">
                        <el-button type="primary" size="small" plain  @click="onSubmit">查询</el-button>
                    </div>  
            </el-form-item>
                <el-form-item class="rightbox"> 
                    <el-button type="primary" size="small">新增机构</el-button>
               </el-form-item>
            </el-form>
        
        </div>
        <!--新建角色弹窗-->
        <el-dialog title="创建角色" :visible.sync="dialogFormVisible" width="30%">
            <el-form ref="newform" :model="newform" label-width="100px" :rules="rules">
                <el-form-item label="角色名称" prop="name">
                    <el-input v-model="newform.name" placeholder="请填写角色名称"></el-input>
                </el-form-item>

                <el-form-item label="角色权限" label-width="100px" prop="manager">
                    <el-tree
                        :data="options"
                        show-checkbox
                        node-key="id"
                        :default-checked-keys="[1]"
                        :default-expanded-keys="[4]"
                        :expand-on-click-node="true"
                        v-model="newform.manager"
                    >
                    </el-tree>
                </el-form-item>

                <el-form-item label="角色描述">
                    <el-input v-model="newform.describe" placeholder="请填写角色描述" type="textarea"></el-input>
                </el-form-item>
                
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel()">取 消</el-button>
                <el-button type="primary" @click="determine('newform')">确 定</el-button>
            </div>
        </el-dialog>

        <div class="Tablebox">
            <el-table
            :data="tableData"
            border
            :style="{width: '100%','margin-bottom':'2rem'}"
            :header-cell-style="{background:'#f5f7fa'}">
                <el-table-column v-for="(item, index) in tableList" :key="index" :prop="item.prop" :label="item.label"></el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="120">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="text" size="small">编辑</el-button>
                        <el-button type="text" size="small" @click="handleDelete(scope.$index, scope.row)" slot="reference">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page="paging.currentPage"
                :page-sizes="[10]"
                :page-size="paging.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="paging.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                rules: {
                    name: [
                        { required: true, message: '请输入部门名称', trigger: 'blur' },
                    ],
                    manager:[
                         { required: true, message: '请选择角色权限', trigger: 'blur' },
                    ]
                },
                dialogFormVisible: false,
                newform: {
                    name: '',
                    manager:[],
                    describe:''
                },
                //新增角色列表
                options:[
                    {
                        id: 1,
                        label: '项目管理',
                        children: [
                            {
                                id: 4,
                                label: '二级 1-1',
                                children: [
                                    {
                                        id: 9,
                                        label: '三级 1-1-1'
                                    }, 
                                    {
                                        id: 10,
                                        label: '三级 1-1-2'
                                    }
                                ]
                            }
                        ]
                    }, 
                    {
                        id: 2,
                        label: '数据查询',
                        children: [
                            {
                                id: 5,
                                label: '二级 2-1'
                            }, 
                            {
                                id: 6,
                                label: '二级 2-2'
                            }
                        ]
                    }, 
                    {
                        id: 3,
                        label: '系统管理',
                        children: [
                            {
                                id: 7,
                                label: '部门管理'
                            }, 
                            {
                                id: 8,
                                label: '角色管理',
                                children: [
                                    {
                                        id: 11,
                                        label: '三级 3-2-1'
                                    }, 
                                    {
                                        id: 12,
                                        label: '三级 3-2-2'
                                    }, 
                                    {
                                        id: 13,
                                        label: '三级 3-2-3'
                                    }
                                ]
                            },
                            {
                                id: 14,
                                label: '人员管理'
                            }, 
                            {
                                id: 15,
                                label: '机构管理'
                            }, 
                        ]
                    },
                    {
                        id: 16,
                        label: '作业监测',
                        children: [
                            {
                                id: 17,
                                label: '二级 1-1',
                                children: [
                                    {
                                        id: 18,
                                        label: '三级 1-1-1'
                                    }, 
                                    {
                                        id: 19,
                                        label: '三级 1-1-2'
                                    }
                                ]
                            }
                        ]
                    }, 
                ],
                 //列表展示的值
                tableList:[
                    {
                        prop:'number',
                        label:'部门编号'
                    },
                    {
                        prop:'name',
                        label:'角色名称'
                    },
                    {
                        prop:'describe',
                        label:'角色描述'
                    }
                ],
                
                //搜索关键词
                form: {
                    name: '',
                    handled:[],
                },
                //表单数据
                 tableData: [
                    {
                        number: '1',//角色编号
                        name: '管理员',//角色名称
                        describe: '拥有系统最高权限',//角色描述
                    },{
                        number: '2',
                        name: '部门经理',
                        describe: '业务系统、管理所在部门人员（增删改）、机构管理（增删改）',
                    },{
                        number: '3',
                        name: '成员',
                        describe: '业务系统',
                    },{
                        number: '4',
                        name: '-',
                        describe: '-',
                    },{
                        number: '5',
                        name: '-',
                        describe: '-',
                    },{
                        number: '6',
                        name: '-',
                        describe: '-',
                    },{
                        number: '7',
                        name: '-',
                        describe: '-',
                    },{
                        number: '8',
                        name: '-',
                        describe: '-',
                    },{
                        number: '9',
                        name: '-',
                        describe: '-',
                    },{
                        number: '10',
                        name: '-',
                        describe: '-',
                    }
                ],
                //分页
                paging:{
                    currentPage:4,//当前加载页
                    size:10,//每页显示条目个数
                    total:400,//总条目数
                }
                
                
            }
        },
        methods: {
            //新建角色
            newbuild(){
                this.dialogFormVisible=true
            },
            cancel(){
                this.dialogFormVisible=false
            },
            determine(newform){
                console.log(this.newform)
                this.$refs[newform].validate((valid) => {
                if (valid) {
                    this.$message({
                        message: '创建成功',
                        type: 'success'
                    });
                    this.newform.name='';
                    this.newform.manager=[];
                    this.dialogFormVisible=false   
                } else {
                    this.$message.error('请正确填写信息');
                    }
                });
            },
            //查询按钮
            onSubmit() {
                console.log(this.form);
            },
            //查看
            handleClick(row) {
                console.log(row);
            },
            //删除
            handleDelete(index, row){
                this.$confirm('您确定删除编号为：'+row.number+'的文件吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                .then(() => {
                    this.tableData.splice(index, 1);
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                })
                .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        });          
                });
                console.log(index, row);
            },
            //分页
            //当前加载页 改变时会触发
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
            }
        }
        
    }
</script>

<style lang="less" scoped>
    .mianbox{
        padding: 1.5rem;
        .searchbox{
            padding: 2rem;
            background-color: #ffffff;
            box-shadow: 0 0 0.6rem #e0e0e0;
            border-radius:0.5rem ;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .searchbox_left{
                .leftbox{
                    display: flex;
                    align-items: center;
                    .left_one{
                        width: 26.8rem;
                    }
                    .left_two{
                        width: 28rem;
                        margin-left:2rem ;
                        .el-form-item{
                            margin-bottom: 0 !important;
                        }
                    }
                    .left_three{
                        margin-left:2rem ;
                    }
                }
            }
            // .rightbox{

            // }
        }
        .Tablebox{
            padding: 2rem;
            background-color: #ffffff;
            box-shadow: 0 0 0.6rem #e0e0e0;
            border-radius:0.5rem ;
            margin-top: 1.5rem;
        }
    }
</style>